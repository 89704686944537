<template>
  <div class="contractSummaryPage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div v-if="!questionnaireResponse">
              Loading...
            </div>

            <div v-else>
              <div class="intro">
                <base-heading type="4">
                  Jammer dat je wilt stoppen!
                </base-heading>

                <p v-html-safe="questionnaireResponse.motivation" />
              </div>

              <div v-if="Object.keys(questionnaireResponse.trainers).length" class="white_container">
                <base-heading type="4">
                  We zijn ontzettend trots op wat je hebt bereikt!
                </base-heading>

                <h4 class="sub_title">
                  Scroll naar beneden om definitief op te zeggen.
                </h4>

                <div
                  v-for="(trainer, index) in questionnaireResponse.trainers"
                  v-bind:key="index"
                  class="trainers"
                >
                  <div class="trainer_img">
                    <img
                      v-bind:src="trainer.img_url"
                      v-bind:alt="trainer.program_name"
                    />
                  </div>

                  <div class="trainer_text">
                    <base-heading type="4">
                      {{ trainer.program_name.replace(`met ${trainer.trainer_name}`, '') }}
                    </base-heading>

                    <h4>
                      met {{ trainer.trainer_name }}
                    </h4>

                    <p>
                      Je hebt samen met {{ trainer.trainer_name }}:
                      <br />
                      <strong>{{ trainer.workouts_done }}</strong> van
                      <strong>{{ trainer.workouts_total }}</strong> trainingen gedaan
                      <br />
                      <strong>{{ trainer.recipes_done }}</strong> van
                      <strong>{{ trainer.recipes_total }}</strong> recepten bekeken
                    </p>
                  </div>
                </div>

                <div class="summary">
                  <base-heading type="4">
                    {{ questionnaireResponse.summary.days_member }} dagen
                  </base-heading>

                  <p>
                    Je bent begonnen met Fitchannel op
                    {{ questionnaireResponse.summary.start_date }}

                    <br />
                    <br />
                    <br />

                    <span v-if="questionnaireResponse.summary.total_workouts >= 1">
                      In die tijd heb je gedaan:
                      <br />
                      <strong>{{ questionnaireResponse.summary.total_workouts }}</strong> trainingen
                      <br />

                      <span v-if=" questionnaireResponse.summary.total_waist >= 1">
                        <strong>{{ Math.abs(questionnaireResponse.summary.total_waist) }} cm</strong>
                        <span v-if="questionnaireResponse.summary.total_waist > 0">minder</span>
                        <span v-else>meer</span> taile
                        <br />
                      </span>

                      <span v-if="questionnaireResponse.summary.total_hip >= 1">
                        <strong>{{ Math.abs(questionnaireResponse.summary.total_hip) }} cm</strong>

                        <span v-if="questionnaireResponse.summary.total_hip > 0">
                          minder
                        </span>

                        <span v-else>
                          meer
                        </span>

                        heupomtrek
                        <br />
                      </span>

                      <strong>{{ Math.ceil(questionnaireResponse.summary.total_minutes_watched / 60) }} uur</strong> gesport
                      <br />

                      <span v-if="questionnaireResponse.summary.recipe_favorites >= 1">
                        <strong>{{ questionnaireResponse.summary.recipe_favorites }} recepten</strong> favoriet gemaakt
                        <br />
                      </span>
                    </span>
                  </p>
                </div>
              </div>

              <div v-else class="white_container">
                <base-heading type="4">
                  Heb je het een kans gegeven?
                </base-heading>

                <p>
                  Jij hebt nog geen workouts gedaan. Misschien wil je het eerst eens proberen? Ga naar <router-link :to="{ name: 'programs' }">programma's</router-link> om te beginnen.
                </p>
              </div>

              <div id="contract_footer">
                <div>
                  <p v-if="questionnaireResponse.repeal === false">
                    Klik op “Definitief opzeggen” om je account stop te zetten. Je abonnement loopt dan nog door tot <strong>{{ questionnaireResponse.contract_end_date }}</strong>.
                  </p>

                  <p v-else>
                    Klik op “Definitief opzeggen” om je account stop te zetten. Je abonnement eindigt <strong>direct</strong>. Je krijgt je geld zo spoedig mogelijk teruggestort.
                  </p>

                  <p v-if="error" class="input-error-message">
                    {{ error }}
                  </p>

                  <base-button
                    modifiers="primary block fullWidth"
                    v-bind:show-spinner="isSaving"
                    v-bind:disabled="!!isSaving"
                    loading-text="Aan het opzeggen"
                    v-on:click="postEndContract"
                  >
                    Definitief opzeggen
                  </base-button>
                </div>

                <div>
                  <base-button
                    modifiers="secondary block fullWidth"
                    v-bind:to="{ name: 'profile.overview' }"
                    class="button button--secondary"
                  >
                    Ik wil nog nadenken
                  </base-button>
                </div>
              </div>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseHeading,
    BaseButton,
  },

  data() {
    return {
      isSaving: false,
      error: null,
    };
  },

  computed: {
    ...mapState('user', ['questionnaireResponse']),
  },

  created() {
    if (!this.questionnaireResponse) {
      this.$router.push({ name: 'contract.questionnaire' });
    }
  },

  unmounted() {
    this.$store.commit('user/setQuestionnaireResponse', null);
  },

  methods: {
    async postEndContract() {
      this.isSaving = true;

      try {
        await this.$store.dispatch('user/endContract');
        this.$router.push({ name: 'contract.end' });
      } catch (error) {
        this.error = error;
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contractSummaryPage {
  padding: 20px 0;
}
</style>
